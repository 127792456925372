.about{
  padding: 5vmin 0 0;
  margin: 0 -15px;
  &-item{
    display: flex;
    align-items: center;
    padding: 15px 0;
    svg{
      display: inline-block;
      color: $brand-primary;
      width: 60px;
      height: auto;
    }
    &-text{
      padding-left: 20px;
      &__title{
        display: block;
        padding: 0 0 10px;
        font-weight: 900;
        font-size: 22px;
        line-height: 1.2;
      }
    }
  }

  &-box{
    padding: 5vmin;
    background-color: $white;
    border-top: 5px solid $brand-primary;
    box-shadow: 3px 5px 10px #00000029;
    border-radius: 5px;
    &-text{
      color: $black;
      p{
        color: $black;
      }
    }
    &-logo{
      padding:  15px;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      img{
        max-width: 70%;
        height: auto;
      }
    }
  }
}

#index .about{
  &-box{
    margin: -10vmin 0 0;
    transform: translateY(15vmin)
  }
}

@media(min-width: 992px){
  .about{
    &-item{
      &.last{
        justify-content: flex-end;
      }
    }
    &-box{
      background-image: url(../img/about-background.webp);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right bottom;
    }
  }
}