.carousel {
  padding: 0;
  margin: 0 -15px;
  overflow: hidden;

  &-inner {
    margin: 0;
    > .carousel-item{
    }
  }

  &-item {
    figure {
      margin: 0;
      padding: 0 15px;
      background: $white;
    }
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    height: 100%;
    padding: 25px;
    margin: 0 -15px;
  }

  &-title {
    padding: 0 0 15px;
    margin: 0;
    font-family: $secondary-font;
    font-size: 7vmin;
    font-weight: 400;
    letter-spacing: 1px;
    color: $black;
    word-break: break-word;
  }

  &-description {
    display: block;
    width: 100%;
    color: $gray;
    padding: 0 0 15px;
    line-height: 1.8;
    p{
      color: $gray;
    }
    b, strong{
      font-size: 20px;
      font-weight: bold;
      color: $brand-primary
    }
  }

  &-image {
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0 -15px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .direction {
    z-index: auto;
  }

  .carousel-control {
    // width: 50px;
    opacity: 1;

    .icon-next,
    .icon-prev {

      @at-root .lang-rtl & {
        .material-icons {
          transform: rotate(-180deg);
        }
      }

      &::before {
        content: "";
        display: none;
      }

      i {
        font-size: 3.125rem;
        color: $white;
      }

      &:hover {
        i {
          color: $brand-primary;
        }
      }
    }

    .icon-prev {
      // left: 1rem;
    }

    .icon-next {
      // right: 2rem;
    }

    &.left,
    &.right {
      background: none;
    }
  }
}

.carousel-indicators {
  width: 100%;
  right: 0;
  lefT: auto;
  margin: 0;
  text-align: center;

  li {
    border-color: $brand-primary;
  }

  .active {
    background-color: $brand-primary;
  }
}


@media(min-width: 992px) {
  .carousel {
    &-info {
      padding: 10vmin;
    }

    &-image {
      img {}
    }
  }

  .carousel-indicators {
    padding-right: 20vmin;
    text-align: right;

    li {
      border-color: #fff;
    }

    .active {
      background-color: #fff;
    }
  }
}


.net-slider{
  margin: 0 -15px;
  &-item{
    position: relative;
    &-image{
      display: block;
      width: 100%;
      min-height: 400px;
      object-fit: cover;
      object-position: top center;
      filter: brightness(0.8);
      @media(min-width: 992px){
        filter: none;
      }
    }
    &-info{
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      align-content: center;
      height: 100%;
      padding: 5vmin;
      box-sizing: border-box;
      flex-wrap: wrap;
      &-title{
        display: block;
        width: 100%;
        color: #fff;
        font-size: unquote('clamp(1.375rem, 0.2813rem + 3.5vw, 2.25rem)');
      }
      &-desc{
        display: block;
        width: 100%;
        padding: 25px 0;
        color: #fff;
      }
    }
  }
}