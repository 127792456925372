.sale-products
{
  &-bg{
    padding: 0;
    background-color: $white;
    box-shadow: 3px 5px 10px #00000029;
    border-radius: 5px;
  }
  &-box{
    height: 100%;
    padding: 50px;
    color: #fff;
    background: url(../img/sale-background.webp) no-repeat;
    background-size: cover;
    background-position: bottom;
    // border-radius: 5px;
  }
  &-title{
    font-weight: 900;
    font-size: 42px;
    margin: 0;
    padding: 0;
  }
  &-desc{
    padding: 10px 0;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
  }
  // .swiper-container{
  //   padding: 15px;
  // }
  &-list{
    padding: 15px;
  }
  .product-miniature{
    margin: 0!important;
  }
}

.featured-products
{
  &-bg{
    padding: 0;
    background-color: $white;
    box-shadow: 3px 5px 10px #00000029;
    border-radius: 5px;
  }
  &-box{
    height: 100%;
    padding: 50px;
    color: #fff;
    background: url(../img/featured-background.webp) no-repeat;
    background-size: cover;
    background-position: bottom;
    // border-radius: 5px;
  }
  &-title{
    font-weight: 900;
    font-size: 42px;
    margin: 0;
    padding: 0;
  }
  &-desc{
    padding: 10px 0;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
  }
  // .swiper-container{
  //   padding: 15px;
  // }
  &-list{
    padding: 15px;
  }
  .product-miniature{
    margin: 0!important;
  }
}

.new-products
{
  &-bg{
    padding: 0;
    background-color: $white;
    box-shadow: 3px 5px 10px #00000029;
    border-radius: 5px;
  }
  &-box{
    height: 100%;
    padding: 50px;
    color: #fff;
    background: url(../img/featured-background.webp) no-repeat;
    background-size: cover;
    background-position: bottom;
    // border-radius: 5px;
  }
  &-title{
    font-weight: 900;
    font-size: 42px;
    margin: 0;
    padding: 0;
  }
  &-desc{
    padding: 10px 0;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
  }
  // .swiper-container{
  //   padding: 15px;
  // }
  &-list{
    padding: 15px;
  }
  .product-miniature{
    margin: 0!important;
  }
}

.similar-products
{
  padding: 5vmin 0;
  &-bg{
    padding: 0;
    background-color: $white;
    box-shadow: 3px 5px 10px #00000029;
    border-radius: 5px;
  }
  &-box{
    height: 100%;
    padding: 50px;
    color: #fff;
    background: url(../img/similar-background.webp) no-repeat;
    background-size: cover;
    background-position: bottom;
    // border-radius: 5px;
  }
  &-title{
    font-weight: 900;
    font-size: 42px;
    margin: 0;
    padding: 0;
  }
  &-desc{
    padding: 10px 0;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
  }
  // .swiper-container{
  //   padding: 15px;
  // }
  &-list{
    padding: 15px;
  }
  .product-miniature{
    margin: 0!important;
  }
}

.carousel{
  &-btns{
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 10;
  }
  &-btn{
    display: inline-block;
    // display: flex;
    // align-items: center;
    // position: absolute;
    // top: 0;
    // height: 100%;
    padding: 0 5px;
    color: $white;
    transition: .4s all ease-in-out;
    svg{
      width: 20px;
      height: auto;
    }
    &.prev{
      &:hover {
        // transform: translateX(-5px);
      }
    }
    &.next{
      &:hover {
        // transform: translateX(5px);
      }
    }
    &.swiper-button-disabled{
      opacity: .4;
    }
  }
  &-item{
    background-color: $white;
    border-right: 1px solid $gray-light;
  }
}

@media(min-width: 992px)
{
  .carousel-btns{
    display: block;
  }
}


.new-products,
.sale-products,
.featured-products,
.specials-products {
  padding: 10vmin 15px;
  margin: 0 -15px;
  background: $brand-secondary;
}
// .variant-tooltip {
//   display: none;
//   position: absolute;
//   top: -125px;
//   left: -40px;
//   width: 100px;
//   min-height: 120px;
//   font-size: 12px;
//   text-align: center;
//   background: rgba(0, 0, 0, .8);
//   border: 2px solid $black;
//   border-radius: 5px;

//   .variant-color {
//     width: 96px;
//     height: 85px;
//     margin: 0 0 5px;

//     &.texture {
//       background-size: cover;
//       background-repeat: no-repeat;
//     }
//   }

//   .variant-title {
//     color: $white;
//   }
// }
#products,
.featured-products,
.new-products,
.sale-products,
.products-same-category,
.product-accessories,
.product-miniature,
.category-products {
  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 25px 0;
    margin: 0;
    background: #fff;
    box-shadow: 3px 5px 10px #00000029;
  }

  .product-thumbnail {
    display: block;
    border-radius: 15px;
  }

  .thumbnail-container {
    position: relative;
    height: auto !important;
    background: white;

    .product-thumbnail {
      position: relative;

      img {
        width: 100%;
        height: auto;
        margin: auto;
        background: #fff;
      }
    }
  }

  .products-section-title {
    text-align: center;
  }

  .product-price-and-shipping {
    margin: 0 0 5px;
    font-size: 22px;
    color: $brand-primary;
    font-weight: 600;

    .discount-product {
      display: none;
    }
    .price{
      display: block;
      &.without-tax{
        font-size: 15px;
        font-weight: 400;
        color: #7a7a7a;
      }
    }
  }

  .thumbnail-container {
    // overflow: hidden;
    transition: .3s all ease-in-out;
    &:hover {

      .product-buttons {
        bottom: 15px;
      }
    }
  }

  .product-description {
    height: auto !important;
    padding: 10px 0;
    text-align: center;
  }

  .product-btn{
    padding: 0 0 15px;
    text-align: center;
  }

  .product-btn-qty{
    display: block;
    padding: 5px;
    margin: 0 auto 10px;
    border: 1px solid #eee;
    text-align: center;
  }

  .product-reference {
    display: block;
    margin: 0 0 5px;
    font-size: 14px;
    color: #C1BFBF;
  }

  .product-title {
    position: relative;
    text-transform: inherit;
    margin: 0;
    // min-height: 60px;

    a {
      display: block;
      line-height: 1.4;
      // font-family: $secondary-font;
      font-size: 18px;
      color: $black;
      text-decoration: none;
      font-weight: 400;
    }
  }

  .product-category {
    display: block;
    padding: 10px 0;
    color: $gray-second;
  }

  .product-availability {
    display: block;
    margin: 0 0 5px;
    font-size: 14px;
    color: $gray;
  }

  .add-to-cart-or-refresh {
    display: block;
    margin: 1rem 0 0;
  }

  .product-miniature {
    margin: 0 0 2.5rem;
    background-color: $white;
    // border: 1px solid $gray-light;
    // border-radius: 5px;
    // overflow: hidden;

    .product-flags {
      li.product-flag {}
    }
    &:hover{
      .thumbnail-container{
        // box-shadow: 0 20px 20px -16px rgba(0, 0, 0, .5);
      }
    }
  }

  .comments_note {
    text-align: center;
    color: $gray;
  }

  .regular-price {
    color: $black;
    text-decoration: line-through;
    font-size: $font-size-sm;
    display: inline-block;
  }

  .count {
    color: $gray;
    font-weight: 700;
    position: relative;
    bottom: 0.5rem;
  }

  .all-product-link {
    clear: both;
    color: $gray;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .product-miniature-list {
    display: flex;
    flex-wrap: wrap;

    .thumbnail-container {
      width: 50%;
    }

    .product-description {
      padding: 20px;
      width: 50%;
    }
  }
  .variant-links{
    .color{
      position: relative;
      width: 25px;
      height: 25px;
      margin: 2px;
      border-radius: 100%;

      &:hover{
        .variant-tooltip{
          display: block;
        }
      }
    }
  }
}

.featured-title {
  font-family: $secondary-font;
  font-weight: 600;
  font-size: 22px;
  margin: 1.5rem 0;
  padding: 0 0 1rem;
  color: $brand-secondary;
  border-bottom: 1px solid #DDDCDC;
}

@include media-breakpoint-down(sm) {

  #products,
  .featured-products,
  .product-accessories {
    .thumbnail-container {
      .product-description {
        // box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .featured-products {
    .products {
      justify-content: center;
    }
  }
}
