.cart-grid {
  margin-bottom: 1rem;
}

.cart-items {
  margin-bottom: 0;
}

.cart-item {
  padding: 1rem 0;
}

.card-block {
  padding: 25px;

  &.checkout {
    padding-top: 0;
    padding-bottom: 1.75rem;

    .btn-primary {
      width: 100%;
      white-space: normal;
    }
  }

  &.cart-detailed-subtotals + .cart-summary-totals .cart-total {
    border-top: 1px solid $border-gray;
  }

  &.cart-summary-totals {
    padding: 0 1.25rem 0.825rem;

    .cart-summary-line {
      padding: 0.5rem 0.2rem 0;

      &:last-child {
        margin-bottom: 0;
      }

      &.cart-total {
        padding: 1rem 0;
        display: grid;
        grid-column: 2;
        grid-template-columns: 1fr 1fr;




        .label,
        .value {
          font-weight: 700;
        }

        .label {
          text-align: left;
          font-size: 1rem;
        }

        .value {
          text-align: right;
          font-size: 1rem;
        }
      }

      span {
        &.sub {
          float: none;
          font-size: 0.88rem;
          text-align: left;
        }
      }
    }
  }
}

.cart-summary-line {
  @include clearfix();
  margin-bottom: 0.315rem;
  clear: both;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .label {
    padding-left: 0;
    font-weight: 400;
    color: $gray-dark;
    white-space: inherit;
  }

  .value {
    float: right;
    font-weight: 600;
    color: $gray-darker;
  }

  &.cart-summary-subtotals {
    .label,
    .value {
      font-weight: 500;
    }
  }

  .label,
  .value {
    font-size: 1rem;
  }
}

/** CART BODY **/
.cart-grid-body {
  margin-bottom: 0.75rem;

  a.label {
    &:hover {
      color: $brand-primary;
    }
  }

  .card-block {
    padding: 1rem;

    h1 {
      margin-bottom: 0;
      font-size: 1.5rem;
      font-weight: 400;
    }
  }

  .cart-overview {
    padding: 1rem;
  }
}

/** CART RIGHT **/
.cart-grid-right {
  .promo-discounts {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    text-align: center;

    .cart-summary-line {
      .label {
        font-size: 1rem;
        color: $gray;

        .code {
          color: $warning-second;
          cursor: pointer;
        }
      }
    }
  }

  .cart-summary-line {
    &.cart-subtotal-products {
      .label,
      .value {
        font-weight: 600;
      }

      .value {
        font-size: 1rem;
      }
    }
  }
}

.block-promo {
  margin-bottom: 1rem;

  .promo-code {
    padding: 1rem;

    .alert-danger {
      position: relative;
      display: none;
      margin-top: 1.25rem;
      color: $white;
      background: $brand-danger;

      &::after {
        position: absolute;
        bottom: 100%;
        left: 10%;
        width: 0;
        height: 0;
        margin-left: -10px;
        pointer-events: none;
        content: " ";
        border: solid transparent;
        border-width: 10px;
        border-bottom-color: $brand-danger;
      }
    }

    form {
      text-align: center;
    }
  }

  .promo-input {
    width: 50%;
    height: 2.5rem;
    margin-right: -0.24rem;
    color: $gray-darker;
    text-indent: 0.625rem;
    border: $gray 1px solid;

    + button {
      margin-top: -4px;
      margin-left: -1px;
      text-transform: capitalize;
      border: 2px solid $brand-primary;
    }
  }

  .cart-summary-line .label,
  .promo-name {
    margin-bottom: 0;
    font-weight: 600;
    color: $brand-warning;

    a {
      display: inline-block;
      font-weight: 500;
      color: $gray-darker;
    }
  }

  .promo-name {
    .cart-summary-line {
      .label {
        font-weight: 500;
        color: $gray-dark;
      }

      span {
        color: $warning-second;
      }
    }

    .material-icons {
      margin-left: 0.4rem;
      font-size: 1.2rem;
    }
  }

  .promo-code-button {
    display: block;
    padding: 0 0 1rem;
    text-align: center;

    &.with-discounts {
      display: none;
    }

    &.cancel-promo {
      margin-bottom: 0;
      color: $brand-primary;

      &[aria-expanded="true"] {
        display: block;
        padding: 0;
        margin-top: 1rem;
      }
    }
  }

  &.promo-highlighted {
    padding: 0 1rem;
    margin-bottom: 0;
    text-align: center;
  }

  p {
    margin-bottom: 0;
  }
}

/** CONTENT LEFT **/
.product-line-grid-left {
  img {
    max-width: 100%;
  }
}

/** CONTENT BODY **/
.product-line-grid-body {
  > .product-line-info {
    > .label {
      padding: 0;
      font-weight: 600;
      line-height: inherit;
      text-align: left;
      white-space: inherit;
    }

    > .out-of-stock {
      color: #f00;
    }

    > .available {
      color: $brand-success;
    }

    .unit-price-cart {
      overflow: hidden;
      font-size: 0.875rem;
      font-weight: 500;
      color: $gray;
      text-overflow: ellipsis;
    }
  }
}

/** CONTENT LEFT **/
.product-line-grid-right {
  .cart-line-product-actions,
  .product-price {
    line-height: 36px;
    color: $gray-darker;

    .remove-from-cart {
      display: inline-block;
      margin-top: 0.3125rem;
      color: $gray-darker;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .product-line-grid-body {
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-down(xs) {
  .cart-items {
    padding: 1rem 0;
  }

  .cart-item {
    border-bottom: 1px solid $gray-light;

    &:last-child {
      border-bottom: 0;
    }
  }

  .cart-grid-body {
    .cart-overview {
      padding: 0;
    }

    .no-items {
      display: inline-block;
      padding: 1rem;
    }
  }

  .product-line-grid-left {
    // stylelint-disable-next-line
    padding-right: 0 !important;
  }
}

@media (max-width: 360px) {
  .product-line-grid-right {
    .qty {
      width: 100%;
    }

    .price {
      width: 100%;
    }
  }
}
