.search{
  &-overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    z-index: 999;
    &.is-active{
      display: block;
    }
  }
  &-modal{
    position: relative;
    width: 40vw;
    height: auto;
    padding: 50px;
    margin: auto;
    margin-top: 20vh;
    background: #fff;
    border-radius: 5px;
    &-header{
      display: flex;
      justify-content: flex-end;
      .modal-close{
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 22px;
        color: $brand-primary;
        cursor: pointer;
      }
    }
    &-body{
      form{
        position: relative;
        display: flex;
        input[type="text"]{
          width: 100%;
          padding: 10px;
          padding-right: 50px;
          border: 1px solid $gray-light;
          outline: none;
        }
        button[type="submit"]{
          position: absolute;
          top: 0;
          right: 0;
          padding: 10px 20px;
          border: none;
          background: $brand-secondary;
          border: 1px solid $gray-light;
          cursor: pointer;
        }
      }
    }
  }

}

.search-widgets{
  form{
    position: relative;
    display: flex;
    border:1px solid #fff;
    input[type="text"]{
      width: 100%;
      min-width: 200px;
      padding: 10px;
      font-size: 14px;
      border: 1px solid $gray-light;
      outline: none;
    }
    button[type="submit"]{
      padding: 10px 20px;
      border: none;
      background: $brand-secondary;
      border: 1px solid $gray-light;
      cursor: pointer;
    }
  }
}