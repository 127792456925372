.subcategory{
  &-heading{
    font-size: 22px;
    font-weight: 900;
    padding: 0 0 10px;
    margin: 0;
  }
  &-btns{
    padding: 10px 0 2rem;
    .btn{
      margin: 10px;
      text-transform: capitalize;
    }
  }
}