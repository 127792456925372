.homecategory{
  position: relative;
  padding: 5vmin 0;
  &-image{
    position: absolute;
    right:0;
    top:0;
    img{
      display: block;
      width: 100px;
      height: auto;;
    }
  }
  &-item{
    display: block;
    position: relative;
    height: calc(100% - 10px);
    padding: 90px 15px 15px;
    margin: 0 -10px 10px;
    background: $brand-secondary;
    transition: .3s all ease-in-out;
    &:hover{
      box-shadow: 3px 5px 10px #00000029;
      border-radius: 5px;
      .homecategory{
        &-info{
          &__more{
            svg{
              transform:translateX(10px)
            }
          }
        }
      }
    }
  }
  &-info{
    color: $gray-dark;
    &__title{
      margin: 0;
      padding: 0;
      font-size: 27px;
      font-weight: 900;
    }
    &__subtitle{
      display: block;
      padding: 10px 0 0;
      font-size: 20px;
      font-weight: 300;;
    }
    &__more{
      padding: 10px 0 0;
      text-align: right;
      color: $brand-primary;
      svg{
        width: 40px;
        transition: .3s all ease-in-out ;
      }
    }
  }
}
@media(min-width: 992px){
  .homecategory{
    &-item{
      padding: 90px 30px 30px;
    }
    &-image{
      img{
        width: 160px;
      }
    }
  }
}