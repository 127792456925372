$white: #fff;
$black: #000;
$gray-dark: #303030;
$gray-darker: #232323;
$gray: #7a7a7a;
$gray-second: #ADADAD;
$gray-light: #f6f6f6;
$gray-light-second: #dbdbdb;
$gray-lighter: #f6f6f6;
$gray-lighter-second: #f7f7f7;
$border-gray: #ddd;
$brand-primary: #850D19;
$brand-secondary: #F7EFEC;
$brand-success: #4cbb6c;
$brand-warning: #ff9a52;
$brand-danger: #ff4c4c;
$current-step-color: #00c062;
$label-color: $gray-darker;
$btn-primary-color: $white;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;
$btn-disabled-background: #f6f6f6;
$btn-disabled-color: #ddd;
$input-border-color: rgba(0, 0, 0, 0.25);
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: 0.3125rem;
$small-space: 0.625rem;
$medium-space: 1.25rem;
$large-space: 1.875rem;
$extra-large-space: 2.5rem;
$warning: #ff9a52;
$warning-second: #f49d6e;
$warning-hover: #fde7bb;
$alert-warning-bg: rgba(255, 154, 82, 0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: $white;
$btn-tertiary-color: #6c868e;
$btn-tertiary-hover: #bbcdd2;
$main-font: 'transat';
$secondary-font: 'transat';
$base-font-size: 1rem;
$font-size-lg: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.8125rem;
$font-size-h1: 1.375rem;
$font-size-h2: 1.3125rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-h6: 0.9375rem;
$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
$text-muted: $gray;
$radio-border-color: #979797;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1660px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1160px,
  xxl: 1300px,
  xxxl: 1540px
) !default;

$grid-gutter-width-base: 30px !default;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base,
  xxl: $grid-gutter-width-base,
  xxxl: $grid-gutter-width-base
) !default;