.subpage-wrapper {
  width: 100%;
  min-height: 70vh;
  padding: 0 15px 0;
}

#product {
  #content {
    position: relative;
    margin: 0 auto;
  }
}

.product-info{
  position: sticky;
  top:10px;
  padding: 25px;
  background: #fff;
  border-top: 5px solid $brand-primary;
  box-shadow: 3px 5px 10px #00000029;
  border-radius: 5px;
}
.images{
  &-container{
    padding: 25px;
    background: #fff;
    box-shadow: 3px 5px 10px #00000029;
    border-radius: 5px;
  }
  &-grid{
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-column-gap: 10px;
    padding: 0 0 25px;
  }
  &-thumbs{
    position: relative;
    &-list{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      padding-right: 10px;
      overflow-y: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 5px
      }
      &::-webkit-scrollbar-track {
        background: #eee
      }

      &::-webkit-scrollbar-thumb {
        background: $brand-primary;
      }

    }
    .thumb{
      min-width: 100%;
      margin: 0 0 10px;
      border:1px solid transparent;
      cursor: pointer;
      &.is-active{
        border-color: $brand-primary;
      }
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  &-cover{
    grid-column: span 5/span 5;
    img{
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &-zoom{
    position: absolute;
    top:20px;
    right: 20px;
    font-size: 15px;
    display: flex;
    width:40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: $white;
    box-shadow: 0 0 10px 2px $gray-light;
    color: $gray;
    z-index: 999;
  }
}

.product-carousel {
  &-btns {
    display: none;
  }

  &-btn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 45%;
    height: 45px;
    padding: 0 10px;
    color: $gray;
    transition: .4s all ease-in-out;
    z-index: 99;

    &.prev {
      left: 0;

      &:hover {
        transform: translateX(-5px);
      }
    }

    &.next {
      right: 0;

      &:hover {
        transform: translateX(5px);
      }
    }

    &.swiper-button-disabled {
      opacity: .4;
    }
  }
}

#product {
  .carousel {
    &-btns {
      display: block;
    }
  }
}

@media(min-width: 992px) {
  .carousel-btns {
    display: block;
  }

}

.product-collection {
  display: block;
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 400;
  a{
    color: $gray;
  }
}

.product-title {
  font-family: $secondary-font;
  font-size: 33px;
  font-weight: 600;
  color: $black;
  // text-transform: uppercase;
  &-box{
    display: flex;
  }
}

.product-reference {
  display: block;
  margin: 0 0 5px;
  font-size: 14px;
  color: $gray;
}

.product-price {
  color: #fff;
  display: inline-block;
}

.product-without-taxes{
  font-size: 18px;
}

.current-price {
  display: inline-block;
  font-size: 36px;
  font-weight: 600;
  color: $brand-primary;
  &-value-tax{
    font-weight: 400;
    font-size: 16px;
    color: $gray;;
  }
}

.product-discount {
  display: inline-block;
  font-size: 26px;
  font-weight: 600;
  color: $black;
  text-decoration: line-through;
}

#product-description-short {
  color: $gray-darker;
}

.product-information {
  font-size: $font-size-lg;
  color: $gray-darker;

  .manufacturer-logo {
    height: 3.125rem;
    border: none;
    margin-bottom: 1rem;
  }


}

.product-baner {
  margin: 15px 0;

  img {
    width: 100%;
    height: auto;
  }
}

.product-description {
  font-size: 16px;
  line-height: 1.6;
  color: $gray-darker;

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
    color: $gray-darker;
    margin: 0 0 10px;
  }

  ul {
    list-style: inherit;
    list-style-position: inside;
    padding-left: .75rem;
  }

  table {
    width: 100%;

    tr {
      td {
        padding: 5px;
        text-align: center;
        justify-content: center;
        vertical-align: middle;
        border: 1px solid #eee;
      }
    }
  }
  h1,h2,h3,h4,h5{
    color: $brand-primary;
  }
  strong, b {
    color: $brand-primary;
    text-transform: uppercase;
  }

  iframe {
    max-width: 100%;
    min-height: 350px;
  }
}

.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
}

.input-container {
  position: relative;
  label{
    margin: 0;
  }
}

.input-radio {
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.radio-label {
  background: white;
  display: inline-block;
  padding: 0.125rem $small-space;
  font-weight: 600;
  border: 1px solid #DDDCDC;
}

.product-actions {
  .product-add-to-cart {
    padding: 15px 0;
  }

  .control-label {
    display: block;
    padding: 0 0 10px;
    color: $black;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }

  .bootstrap-touchspin {
    float: none;
    width: 100%;
  }

  .add-to-cart {
    // margin: 25px 0;
    cursor: pointer;
  }
}

#product .product-quantity {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .qty{
    margin-right: 15px;
    &-select{
      min-width: 120px;
      height: 50px;
      margin-right: 15px;
      padding: 5px 15px;
      font-size:  18px;
      background-color: $white;
      border-color: $gray-second;
      border-radius: 5px;
    }
  }

  input[type="number"][name="qty"] {
    width: 100px;
    padding: 5px 10px;
    color: $gray;
    background: transparent;
    border: 1px solid $gray-second;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &-btns {
    padding: 0 10px;
  }

  .btn-quantity {
    display: block;
    padding: 5px;
    margin: 0 2px;
    width: 30px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    background: $brand-primary;
    // border: 1px solid #C1BFBF;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: darken($brand-primary, 5%);
    }
  }
}

.add-to-cart {

}

.product-discounts {
  // margin-bottom: 1.5rem;

  >.product-discounts-title {
    font-weight: normal;
    font-size: $font-size-sm;
  }

  >.table-product-discounts {
    thead tr th {
      width: 33%;
      padding: $small-space $medium-space;
      background: white;
      border: 0.3125rem $gray-light solid;
      text-align: center;
    }

    tbody tr {
      background: $gray-lighter;

      &:nth-of-type(even) {
        background: white;
      }

      td {
        padding: $small-space $medium-space;
        text-align: center;
        border: 0.3125rem $gray-light solid;
      }
    }
  }
}

.product-prices {
  padding: 15px 0 20px;

  .tax-shipping-delivery-label {
    font-size: 0.8125rem;
    color: $gray;

    .delivery-information {
      padding: 0 0 0 2px;

      &::before {
        content: "-";
        padding: 0 2px 0 0;
      }
    }
  }
}

.product-availability {
  display: block;
  margin: 0 0 5px;
  font-size: 14px;
  color: $gray;
}

.product-discount {
  color: $black;

  .regular-price {
    text-decoration: line-through;
    // font-weight: normal;
    margin-right: $small-space;
  }
}

.has-discount {

  &.product-price,
  p {
    color: $brand-secondary;
  }

  .discount {
    display: inline-block;
    padding: 0.3125rem $small-space;
    margin-left: $small-space;
    font-weight: 600;
    font-size: $font-size-base;
    text-transform: uppercase;
    color: white;
    background: $gray-second;
  }
}

.product-unit-price {
  font-size: $font-size-xs;
  margin-bottom: 0;
}


.tabs {
  margin: 2rem 0;
  // padding: 25px;
  // background: #fff;
  // box-shadow: 3px 5px 10px #00000029;
  // border-radius: 5px;

  .tab-pane {
    // padding: 3vmin 0;
  }

  .nav-tabs {
    position: relative;
    text-align: left;
    border: none;
    padding: 0 0 15px;
    z-index: 2;

    .nav-link {
      font-weight: 400;
      color: white;
      border-radius: 25px;
      border-top-left-radius: 0;
      border: none;

      &:focus,
      &:hover {
        // background: transparent
      }

      &.active {
        color: #fff;
        background-color: darken($brand-primary, 15%);
        border-bottom-right-radius: 0;
        border-radius: 25px;
      }
    }

    .nav-item {
      display: block;
      font-family: $secondary-font;
      // text-transform: uppercase;
      float: none;
      padding: 0;
      margin: 5px;
    }
  }
}

.tab-content {
  // transform: translateY(-2px);
  padding: 25px;
  // border-top: 2px solid $brand-primary;
  border-radius: 5px;
  border-top: 5px solid $brand-primary;
  background-color: #fff;
}

@media(min-width: 992px) {
  .tabs {
    .nav-tabs {
      display: flex;
      align-items: center;
      // border-bottom: 2px solid $gray-light;
      .nav-item {
        display: inline-flex;
        // margin: 0 0 -2px;
      }
    }
  }
}

.product-attachments {
  // margin: 30px 0;

  .attachment-item {
    display: inline-block;
    text-align: center;
    color: $black;
    a{
      display: inline-block;
      color: $black;
    }
    i {
      display: block;
      margin: 0 0 10px;
      font-size: 37px;
      color: $gray;
    }
  }
}
.product-cover {
  position: relative;
  margin-bottom: $medium-space;
  overflow: hidden;
  background: #fff;
  border-radius: 5px;

  img {
    width: 100%;
    height: auto;
    border: 1px solid $gray-light;
    border-radius: 5px;
  }

  .layer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    // background: rgba(0, 0, 0, 0.6);
    text-align: center;
    cursor: pointer;
    @include transition(opacity .7s ease-in-out);

    &:hover {
      opacity: 1;
    }

    .zoom-in {
      font-size: 3.125rem;
      color: $btn-primary-color;
    }
  }
}

.btn-size {
  display: inline-block;
  text-decoration: underline;
  margin: 0 0 15px;
  cursor: pointer;
}

#size-modal {
  .modal {
    &-body {
      table {
        width: 100%;

        tr {
          td {
            text-align: center;
          }
        }
      }
    }
  }
}

#product-modal {
  .modal-content {
    background: transparent;
    border: none;
    padding: 0;

    .modal-body {
      display: flex;
      margin-left: -30%;

      .product-cover-modal {
        background: white;
      }

      .image-caption {
        background: white;
        width: 800px;
        padding: 0.625rem 1.25rem;
        border-top: $gray-light 1px solid;

        p {
          margin-bottom: 0;
        }
      }

      .thumbnails {
        position: relative;
      }

      .mask {
        position: relative;
        overflow: hidden;
        max-height: 49.38rem;
        margin-top: 2.188rem;
        z-index: 1;

        &.nomargin {
          margin-top: 0;
        }
      }

      // .product-images {
      //   margin-left: $extra-large-space;

      //   img {
      //     width: 9.25rem;
      //     cursor: pointer;
      //     background: white;

      //     &:hover {
      //       border: $brand-primary 3px solid;
      //     }
      //   }
      // }

      // .arrows {
      //   height: 100%;
      //   width: 100%;
      //   text-align: center;
      //   position: absolute;
      //   top: 0;
      //   color: white;
      //   right: $large-space;
      //   z-index: 0;

      //   .arrow-up {
      //     position: absolute;
      //     top: -2rem;
      //     opacity: 0.2;
      //   }

      //   .arrow-down {
      //     position: absolute;
      //     bottom: -2rem;
      //   }

      //   i {
      //     font-size: 6.25rem;
      //     display: inline;
      //   }

      //   cursor: pointer;
      // }
    }
  }
}

#blockcart-modal {
  color: $gray-darker;

  .modal-header {
    background: white;

    .close {
      opacity: 1;

      .material-icons {
        color: $gray-dark;
      }
    }
  }

  .modal-body {
    background: $btn-warning-color;
    padding: 3.125rem 1.875rem;

    .divide-right span {
      display: inline-block;
      margin-bottom: 0.3125rem;

      @include media-breakpoint-down(sm) {
        display: block;
        padding: 0 .5rem;
      }
    }
  }

  .modal-dialog {
    max-width: 1140px;
    width: 100%;
  }

  .product-image {
    width: 100%;
    max-width: 9.375rem;
    display: block;
    margin: 0 0 0 auto;
  }

  .modal-title {
    color: #353943;
    font-size: 1rem;
    font-weight: 400;

    i.material-icons {
      margin-right: $large-space;
      color: $brand-success;
    }
  }

  .product-name {
    color: $brand-primary;
    font-size: 1.125rem;

    @include media-breakpoint-down(sm) {
      padding: 0 .5rem;
    }
  }

  .product-price {
    color: $gray-dark;
    display: block;

    @include media-breakpoint-down(sm) {
      padding: 0 .5rem;
    }
  }

  .cart-content {
    padding-left: $extra-large-space;

    p {
      color: $gray-dark;
      display: flex;
      justify-content: space-between;
      padding: 0 .5rem;

      &.product-total {
        background-color: #D8D8D8;
        padding: .5rem;
      }

      &.cart-products-count {
        font-size: 1rem;
        color: $brand-primary;
        font-weight: 600;
      }

      &.product-tax {
        display: inherit;
        font-size: .875rem;
      }

      .label,
      .value {
        font-weight: 600;
      }
    }

    .cart-content-btn {
      display: inline-flex;

      button {
        margin-right: 0.9rem;
      }

      .btn {
        white-space: inherit;
      }
    }
  }

  .divide-right {
    border-right: 1px solid $gray-light-second;
  }
}

// .product-images {
//   >li.thumb-container {
//     display: inline;

//     .thumb {
//       margin-bottom: $small-space;
//       background: #fff;
//       border: 1px solid transparent;
//       border-radius: 5px;
//       cursor: pointer;

//       .images-container & {
//         margin-right: 0.8125rem;
//       }

//       &.selected,
//       &:hover {
//         border: $brand-primary 1px solid;
//       }
//     }
//   }
// }

#main {
  // .images-container {
  //   .js-qv-mask {
  //     white-space: nowrap;
  //     overflow: hidden;

  //     &.scroll {
  //       width: calc(100% - 120px);
  //       margin: 0 auto;
  //     }
  //   }
  // }
}

#product-availability {
  margin-top: 0.625rem;
  display: inline-block;
  font-weight: 700;

  .material-icons {
    line-height: inherit;
  }

  .product-available {
    color: $brand-success;
  }

  .product-unavailable {
    color: $brand-warning;
  }

  .product-last-items {
    color: $brand-warning;
  }
}

#product-details {
  .label {
    font-size: $font-size-base;
    color: $gray-darker;
    font-weight: bold;
  }
}

.product-features-small {
  >dl.data-sheet {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0;

    dd.value,
    dt.name {
      display: flex;
      flex: 1 0 50%;
      align-items: center;
      font-weight: normal;
      padding: $small-space;
      min-height: 2.5rem;
      word-break: normal;
      margin-bottom: .5rem;
      border-bottom: 1px solid $brand-secondary;
    }

    dt.name {
      font-weight: 600;
      color: $brand-primary;
    }

    dd.value {
      // color: #fff;
    }
  }

  .data-sheet:last-of-type {

    dd.value,
    dt.name {
      border-bottom: transparent;
    }
  }
}

.product-features {
  &-title{
    display: block;
    font-weight: 600;
    color: $black;
    padding: 30px 0 10px;
    margin: 0;
  }
  >dl.data-sheet {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0;

    dd.value,
    dt.name {
      display: flex;
      flex: 1 0 50%;
      align-items: center;
      font-weight: normal;
      padding: $small-space;
      min-height: 2.5rem;
      word-break: normal;
      margin: 0;
      background: $gray-lighter;
    }

    dt.name {
      font-weight: 400;
      color: $black;
    }

    dd.value {
      img {
        width: 100px;
        height: auto;
        image-rendering: pixelated;
      }
    }
  }
}

.product-variants {
  >.product-variants-item {
    padding: 10px 0;

    select {
      background-color: transparent;
      width: 200px;
      padding: 8px 25px 10px 15px;
      margin: 0;
      border: none;
      outline: none;
      border: 1px solid $gray-second;
      border-radius: 5px;

      // &:focus {
      //   color: $white;
      //   background: $white;
      // }
    }

    ul li {
      // margin-right: $small-space;
    }

    .color {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      margin: 5px;
      border: 2px solid #fff;
      box-shadow: 0 0 10px 2px $gray-light;
    }
    .input-container{
      &:hover{
        .color{
          border: 2px solid $black;
        }
        .variant-tooltip {
          display: block;
        }
      }
    }
    .input-color,
    .input-radio {

      &:checked+span,
      &:hover+span {
        border: 2px solid $gray-darker;
      }
    }
  }
}

.product-flags {
  pointer-events: none;
  position: absolute;
  width: 100%;
  top: 10px;
  left: 10px;
  display: block;
  z-index: 2;

  li.product-flag {
    pointer-events: auto;
    width: 120px;
    padding: 8px 5px;
    margin: 0 0 5px;
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 600;
    // letter-spacing: 2px;
    text-align: center;
    // text-transform: uppercase;
    color: white;
    background: $brand-primary;
    border-radius: 5px;

    &.online-only {
      z-index: 1;
      font-size: $font-size-xs;
      margin-top: 0;

      &::before {
        content: "\E30A";
        font-family: 'Material Icons';
        vertical-align: middle;
        margin: 0.3125rem;
      }
    }

    &.discount-percentage,
    &.discount-amount,
    &.discount {
      background-color: #ca3333;
    }

    &.on-sale {
      background: #ca3333;
    }

    &.new {
      background: $brand-secondary;
      color: #fff;
    }
  }
}

.product-line-grid {}

.product-customization {
  margin: $extra-large-space 0;

  .product-customization-item {
    margin: $medium-space 0;
  }

  .product-message {
    background: $gray-light;
    border: none;
    width: 100%;
    height: 3.125rem;
    resize: none;
    padding: 0.625rem;

    &:focus {
      background-color: white;
      outline: 0.1875rem solid $brand-primary;
    }
  }

  .file-input {
    width: 100%;
    opacity: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    height: 2.625rem;
    overflow: hidden;
    position: absolute;
  }

  .customization-message {
    margin-top: 20px;
  }

  .custom-file {
    position: relative;
    background: $gray-light;
    width: 100%;
    height: 2.625rem;
    line-height: 2.625rem;
    text-indent: 0.625rem;
    display: block;
    color: $gray;
    margin-top: $medium-space;

    button {
      z-index: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  small {
    color: $gray;
  }
}

.product-pack {
  margin-top: $extra-large-space;

  .pack-product-container {
    display: flex;
    justify-content: space-around;

    .pack-product-name {
      flex: 0 0 50%;
      font-size: 0.875rem;
      color: $gray;
    }

    .pack-product-quantity {
      border-left: $gray-light 2px solid;
      padding-left: $small-space;
    }

    .pack-product-name,
    .pack-product-price,
    .pack-product-quantity {
      display: flex;
      align-items: center;
    }
  }
}

.product-refresh {
  margin-top: $medium-space;
}

.social-sharing {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  ul {
    margin-bottom: 0;
  }

  li {
    height: 2.5rem;
    width: 2.5rem;
    display: inline-block;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    background-position: 0.25rem 0.25rem;
    cursor: pointer;
    margin-left: 0.5rem;
    @include transition(all .2s ease-in);

    a {
      display: block;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      text-indent: 100%;
      overflow: hidden;

      &:hover {
        color: transparent;
      }
    }
  }
}

.product-video{
  position: relative;
  margin: 20px 0;
  video{
    display: block;
    width: 100%;
    height: auto;
    max-height: 70vh;
  }
  &-play {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 10vmin;
    color: $white;
    background-color: rgba(0,0,0,.8);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 0 20px 2px #eee;

    i {
      cursor: pointer;
    }
    &.barfleur{
      background-image: url(../img/Barfleur.jpg);
    }
    &.cochem {
      background-image: url(../img/Cochem.jpg);
    }
    &.engen {
      background-image: url(../img/Engen.jpg);
    }
    &.gordes {
      background-image: url(../img/Gordes.jpg);
    }
    &.kronach {
      background-image: url(../img/Kronach.jpg);
    }
    &.mayen {
      background-image: url(../img/Mayen.jpg);
    }
    &.monschau {
      background-image: url(../img/Monschau.jpg);
    }
    &.waldeck {
      background-image: url(../img/Waldeck.jpg);
    }
    &.walldorf {
      background-image: url(../img/Walldorf.jpg);
    }
  }
}

.products-selection {
  margin-bottom: $medium-space;

  .title {
    color: $gray;
  }
}

#blockcart-modal .cart-content {
  .btn {
    margin-bottom: $small-space;
  }
}

.product-accessories {
  margin: 5vmin 0 0;
}

.related-title {
  display: block;
  margin: 15px 0 25px;
  font-size: 24px;
}

.category-products{
  margin: 0 -15px;
  padding: 10vmin 0 0;
  &-title{
    padding: 0 0 25px;
    &.center{
      text-align: center;
    }
    &-text{
      position: relative;
      display: inline-block;
      font-size: 22px;
      color: $black;
      &::after{
        content:'';
        display: block;
        height: 2px;
        width: 200px;
        margin: 10px auto;
        background: $brand-primary;
      }
    }
  }
}

@media(min-width: 992px) {}

.wishlist-button-add i {
  color: $brand-primary !important;
}
@media(max-width: 992px){
  .wishlist-button-product{
    margin: 20px 0!important;
  }

}

@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
    }
  }

  #product-modal .modal-content .modal-body {
    flex-direction: column;
    margin-left: 0;

    img.product-cover-modal {
      width: 100%;
    }

    .arrows {
      display: none;
    }
  }

  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }

  #blockcart-modal {
    .modal-dialog {
      width: calc(100% - 20px);
    }

    .modal-body {
      padding: 1.875rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  #blockcart-modal {
    .divide-right {
      border-right: none;
    }

    .modal-body {
      padding: 1rem;
    }
  }
}
