/* stylelint-disable */
// @font-face {
//   font-family: "Transat";
//   src:
//   url(../fonts/transat-light.woff2) format("woff2"),
//   url(../fonts/transat-light.woff) format("woff"),
//   url(../fonts/transat-light.ttf) format("opentype");
//   font-display: swap;
//   font-style: normal;
//   font-weight: 300;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: "Transat";
//   src:
//   url(../fonts/transat-regular.woff2) format("woff2"),
//   url(../fonts/transat-regular.woff) format("woff"),
//   url(../fonts/transat-regular.ttf) format("opentype");
//   font-display: swap;
//   font-style: normal;
//   font-weight: 400;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: "Transat";
//   src:
//   url(../fonts/transat-medium.woff2) format("woff2"),
//   url(../fonts/transat-medium.woff) format("woff"),
//   url(../fonts/transat-medium.ttf) format("opentype");
//   font-display: swap;
//   font-style: normal;
//   font-weight: 500;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: "Transat";
//   src:
//   url(../fonts/transat-bold.woff2) format("woff2"),
//   url(../fonts/transat-bold.woff) format("woff"),
//   url(../fonts/transat-bold.ttf) format("opentype");
//   font-display: swap;
//   font-style: normal;
//   font-weight: 700;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: "Transat";
//   src:
//   url(../fonts/transat-black.woff2) format("woff2"),
//   url(../fonts/transat-black.woff) format("woff"),
//   url(../fonts/transat-black.ttf) format("opentype");
//   font-display: swap;
//   font-style: normal;
//   font-weight: 900;
//   font-stretch: normal;
// }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-display: swap;
  src: url(../fonts/MaterialIcons.woff2) format('woff2');
}
@import url('https://use.typekit.net/wcz6xlt.css');
// <link rel="stylesheet" href="https://use.typekit.net/wcz6xlt.css">
/* stylelint-enable */

.material-icons {
  display: inline-block;
  width: 1em;
  height: 1em;
  font-family: "Material Icons", Arial, sans-serif;
  font-size: 24px; /* Preferred icon size */
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  vertical-align: middle;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
