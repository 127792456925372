.promotion{
  padding: 5vmin 0 3vmin;
  &-item{
    display: flex;
    align-items: center;
    padding: 15px 0;
    svg{
      display: inline-block;
      color: $brand-primary;
      width: 60px;
      height: auto;
    }
    &-text{
      padding-left: 20px;
      &__title{
        display: block;
        padding: 0 0 10px;
        font-weight: 900;
        font-size: 22px;
        line-height: 1.2;
      }
    }
  }
}

@media(min-width: 992px){
  .promotion{
    &-item{
      &.last{
        justify-content: flex-end;
      }
    }
  }
}



.footer-title {
  font-family: $secondary-font;
  font-size: 21px;
  font-weight: 700;
  color: $black;
  // text-transform: uppercase;
  margin: 0 0 25px;
}
.block-contact {
  color: $black;
  &-content{
    @media(max-width: 767px){
      padding: 0.625rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-weight: 400;
    word-break: break-word;

    i {
      color: $brand-primary;
      margin-right: 25px;
    }

    span {
      color: $black;
    }
  }
  &__link{
    color: $brand-primary;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}

.linklist {
  .blockcms-title a {
    color: $black;
  }
}

.account-list a {
  color: $black;
}

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-weight: 700;
  font-size: $base-font-size;
}

.block-social {
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // width:40px;
    // height: 40px;
    padding: 5px;
    font-size: 15px;
    color: $white;
    // background: $brand-primary;
    // border-radius: 5px;

    &:hover {
      color: $brand-primary;
    }
    span:hover {
      color: $brand-primary;
    }

    &:before {
      font-size: 18px;
      color: $brand-primary;
      margin-right: 10px;
    }

  }
  &-text {
    // display: none;
  }
  .facebook {
    &::before {
      content: "\f082";
      font-family: 'Font Awesome 5 Brands';
      font-weight: 400;
    }
  }

  .twitter {
    &::before {
      content: "\f081";
      font-family: 'Font Awesome 5 Brands';
      font-weight: 400;
    }
  }

  .linkedin {
    &::before {
      content: "\f08c";
      font-family: 'Font Awesome 5 Brands';
      font-weight: 400;
    }
  }

  .rss {
    &::before {
      content: "\f143";
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
    }
  }

  .youtube {
    &::before {
      content: "\f167";
      font-family: 'Font Awesome 5 Brands';
      font-weight: 400;
    }
  }

  .googleplus {
    &::before {
      content: "\f2b3";
      font-family: 'Font Awesome 5 Brands';
      font-weight: 400;
    }
  }

  .pinterest {
    &::before {
      content: "\f0d3";
      font-family: 'Font Awesome 5 Brands';
      font-weight: 400;
    }
  }

  .vimeo {
    &::before {
      content: "\f194";
      font-family: 'Font Awesome 5 Brands';
      font-weight: 400;
    }
  }

  .instagram {
    &::before {
      content: "\f16d";
      font-family: 'Font Awesome 5 Brands';
      font-weight: 400;
    }
  }
}

.footer-container {
  padding: 4rem 0 4rem;
  background: $brand-secondary;

  li {
    margin-bottom: 0.3125rem;
    img{
      padding: 10px 0;
      max-width: 100%;
    }
  }

  li a {
    font-size: 16px;
    font-weight: 400;
    color: $black;
    cursor: pointer;

    // &::before{
    //   content: '\f0da';
    //   font-family: 'Font Awesome 5 Free';
    //   font-weight: 900;
    //   color: $brand-secondary;
    //   margin-right: 5px;
    // }
    &:hover {
      text-decoration: underline;
    }
  }

  .navbar-toggler {
    color: $black;
  }
}
#index .footer-container{
  padding: 20vmin 0 4rem;
}
.links {
  .collapse {
    display: inherit;
  }
}

.footer-bottom {
  font-size: 13px;
  color: $black;
  background: #fff;

  &-flex{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    svg{
      padding-left: 10px;
    }
  }

  a, strong  {
    color: $brand-primary;
    margin: 0 3px;
  }

  .container {
    padding: 10px 15px;
  }
}

@media(min-width: 992px){
  .footer-bottom{
    &-flex{
      &.left{
        justify-content: flex-start;
      }
      &.right{
        justify-content: flex-end;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer-container {
    box-shadow: none;
    margin-top: 0;

    .links {
      .h3 {
        line-height: 1.5;
        font-size: 1rem;
        margin: 0;
      }

      ul {
        margin-bottom: 0;

        >li {
          padding: 0.625rem;
          // border-bottom: 1px solid white;
          font-weight: bold;

        }
      }
    }
  }

  .links {
    .collapse {
      display: none;

      &.in {
        display: block;
      }
    }

    .title {
      padding: 0.625rem;
      margin: 0 0 10px;
      border-bottom: 1px solid $gray;
      cursor: pointer;

      .collapse-icons .remove {
        display: none;
      }
    }

    .title[aria-expanded="true"] .collapse-icons {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .block-social {
    width: 100%;
    padding: 10px 0;
    text-align: center;
  }

  .block-contact {}
}