#products,
#pagenotfound {
  #main {
    .page-header {
      margin: 2rem 0 3rem;
    }

    .page-content {
      margin-bottom: 10rem;
    }
  }

  .page-not-found {

    h4 {
      margin: 0.5rem 0 1rem;
      font-size: $base-font-size;
      font-weight: 700;
    }

    .search-widget {
      float: none;

      input {
        width: 100%;
      }
    }
  }
}
