.static-menu {
  &-list {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  &-item {
    display: block;
    padding: 0;
    border-bottom: 1px solid #eee;
  }

  &-link {
    display: block;
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    color: $gray;

    // text-transform: uppercase;
    &:hover {
      color: $brand-secondary;
    }
  }
}

@media(min-width: 992px) {
  .static-menu {
    &-list {
      width: auto;
    }

    &-item {
      display: inline-block;
      border-bottom: none;
    }

    &-link {
      padding: 0;
      padding-right: 10px;
      font-weight: 600;
    }
  }
}



.menu {
  position: relative;
  margin-bottom: 0;

  .desktop-nav-toggler{

  }
}

.top-menu {
  &[data-depth="1"]  .category{
    position: relative;
    .desktop-nav-toggler{
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      padding: 0 5px;
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
      line-height: 1;
    }
  }
  &[data-depth="1"] {
    margin: 0.625rem;
    display: flex;
    flex-wrap: wrap;
  }

  &[data-depth="0"] {
    margin-bottom: 0;
  }
  *[data-depth="2"] .top-menu{
    padding: 0 0 10px;
  }

  a:not([data-depth="0"]) {
    display: block;
    padding: 3px 10px;
    font-weight: 400;
    color: $gray;
  }

  a.dropdown-submenu {
    font-weight: 600;
    color: $gray-darker;
    // text-transform: uppercase;
  }

  a[data-depth="0"] {
    padding: 20px 15px;
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: 500;
    color: $black;

    &:hover {
      color: $brand-primary;
      #header & {
        // color: $brand-primary;
        text-decoration: none;
      }
    }
  }

  a[data-depth="1"],
  a[data-depth="2"] {
    padding: 0 0.625rem 0.625rem 0;
  }

  .collapse {
    display: inherit;
  }

  &[data-depth="2"] .submenu {
    display: none;
  }

  .sub-menu {
    z-index: 18;
    width: 100%;
    left: 0;
    // min-width: calc(100% - 30px);
    // margin-left: 15px;
    visibility: hidden;
    border: none;
    box-shadow: 2px 1px 11px 2px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0.5s ease;

    &.collapse {
      visibility: hidden;
      opacity: 0;

      &.in {
        visibility: visible;
        opacity: 1;
      }
    }

    ul[data-depth="1"]>li {
      width: 16%;
      padding: 0 10px;
    }

    a:hover {
      color: $brand-primary;
    }
  }

  .popover {
    max-width: inherit;
    border-radius: 0;
  }
}

.popover.bs-tether-element-attached-top {
  margin-top: 0;
}

#_desktop_top_menu {
  .top-menu {
    &[data-depth="0"] {
      li {
        &:hover {
          .sub-menu {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

#mobile_top_menu_wrapper {
  padding: 15px;
  // background: $white;

  #top-menu {
    margin-bottom: 0.625rem;
  }

  .top-menu {
    .desktop-nav-toggler{
      display: none;
    }
    color: $gray;

    .collapse-icons[aria-expanded="true"] {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .collapse-icons .remove {
      display: none;
    }

    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }

    a[data-depth="0"] {
      padding: 0.625rem;
      font-size: 16px;
      color: $gray;
      border-bottom: 1px solid $gray-lighter;
    }

    .collapse {
      display: none;

      &.in {
        display: block;
      }
    }

    .sub-menu {
      position: static;
      z-index: inherit;
      display: block;
      width: 100%;
      min-width: 100%;
      margin-left: 0;
      overflow: hidden;
      background: $white;
      box-shadow: none;

      &.collapse {
        display: none;

        &.in {
          display: block;
        }
      }

      ul[data-depth="0"]>li {
        border-bottom: 1px solid $gray;
      }

      ul[data-depth="1"] {
        margin: 0;

        >li {
          width: 100%;
          margin: 0;

          a {
            text-transform: none;
          }
        }
      }

      ul {
        display: block;
        padding: 0;
      }

      li>a {
        padding: 0.625rem;
        font-weight: 400;
        color: $gray;
        border-bottom: 1px solid $white;
      }

      ul[data-depth="2"] li a {
        padding-left: 1.25rem;
      }

      ul[data-depth="3"] li a {
        padding-left: 2.5rem;
      }

      ul[data-depth="4"] li a {
        padding-left: 3.75rem;
      }
    }
  }

  .js-top-menu-bottom {
    a {
      color: $gray;
    }

    .language-selector-wrapper {
      padding: 0.625rem;

      .language-selector {
        display: inline;
      }
    }

    .currency-selector {
      padding: 0.625rem;
    }

    #contact-link {
      padding: 0.625rem;
    }

    .user-info {
      padding: 0 0.625rem;

      a {
        display: block;
        width: 100%;
        padding: 0.625rem 0;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .top-menu {
    .sub-menu {
      visibility: visible;
      opacity: 1;
      transition: 0.5s ease-out;
    }
  }
}
