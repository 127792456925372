#products {
  color: #000;

  .products-select {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .up {
    margin: 1rem 0;
  }
}

.block-category {
  padding: 40px;
  margin-bottom: 1.563rem;
  border-top: 5px solid $brand-primary;

  &-title {
    font-size: 40px;
    font-weight: 900;
    color: $black;
    // text-transform: uppercase;
  }


  &-description {
    color: $black;

    p {
      color: $black;
    }
  }

  .block-category-inner {
    display: flex;
    justify-content: space-between;
  }
}

#js-product-list-header {
  display: block;
  margin: 0 0 35px;
  font-size: 46px;
  font-weight: 600;
}

.products-selection {
  margin: 0 0 1rem!important;
  .sort-by-row {
    display: flex;
    align-items: center;
  }

  .sort-by {
    display: flex;
    align-items: center;
    white-space: normal;
    word-break: break-word;
    margin: 10px 0;
    padding-right: 15px;
    color: $gray-second;
    // margin-right: -0.9375rem;
    // margin-left: 0.9375rem;
    // text-align: right;
    // justify-content: flex-end;
    .select-title{
      min-width: 200px;
    }
  }

  .result-per-page{
    display: flex;
    align-items: center;
    white-space: normal;
    word-break: break-word;
    margin: 10px 0;
    color: $black;
    justify-content: flex-end;
  }

  .total-products {
    padding-top: 0.625rem;
  }

  h1 {
    padding-top: 0.625rem;
  }
}

.sort-by-text {
  text-transform: uppercase;
  padding-right: 15px;
}

.products-sort-order,
.brands-sort,
.suppliers-sort {
  min-width: 200px;
  color: $black;


  .select-title {
    display: inline-flex;
    width: 100%;
    // min-width: 150px;
    padding: 5px 10px;
    justify-content:  space-between;
    color: $black;
    background: $white;
    border: 1px solid $gray-light;
    border-radius: 5px;
    // border-radius: $border-radius;
    cursor: pointer;

    i {
      color: $gray
    }
  }

  .select-list {
    display: block;
    color: $gray;
    padding: 5px 10px;

    &:hover {
      background: $brand-primary;
      color: white;
      text-decoration: none;
    }
  }

  .dropdown-menu {
    left: auto;
    width: 100%;
    min-width: auto;
    background: $white;
    border: 1px solid $gray-light;
    border-radius: 0;
    // box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 0;
  }
}

.facet-dropdown {
  color: $gray;
  padding-left: 0;
  padding-right: 0;
  background: $gray-light;
  border: 3px solid transparent;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px 1px $gray-light;

  &.open {
    border: 0;

    >.select-title {
      border: 3px solid $brand-primary;
      background: $gray-lighter;
    }
  }

  .select-title {
    display: inline-flex;
    align-items: center;
    margin-left: 0;
    width: 100%;
    color: $gray-darker;
    background: $gray-light;
    padding: $small-space $small-space $small-space $medium-space;
    cursor: pointer;

    >i {
      margin-left: auto;
    }
  }

  .select-list {
    display: block;
    color: $gray-darker;
    background: $gray-lighter;
    padding: $small-space $medium-space;

    &:hover {
      background: $brand-primary;
      color: white;
      text-decoration: none;
    }
  }

  .dropdown-menu {
    padding: 0;
    margin-top: 3px;
    left: auto;
    width: 100%;
    background: $gray-lighter;
    border: none;
    box-shadow: 1px 1px 1px 1px $gray-light;
  }
}

#search_filters,
#search_filters_brands,
#search_filters_suppliers {
  padding: 25px;
  margin-bottom: 1.563rem;
  background: $white;
  box-shadow: 3px 5px 10px #00000029;
  border-radius: 5px;

  .clear-all-wrapper{
    margin: 0 0 15px;
    .btn{
      width: 100%;
    }
  }

  .facet {
    padding-bottom: 25px;
    // border-bottom: 1px solid #4e4e4e;

    .collapse {
      // display: block;
      // max-height: 200px;
      // overflow-y: auto;
      // padding: 10px 0 0;
    }

    .faceted-slider {
      &.collapse {
        overflow-y: visible;
        position: relative;
        width: 90%;
      }
    }

    .facet-title {
      font-size: 16px;
      color: $black;
    }
    .facet-color{
      display: inline-block;
      .color{
        width: 25px;
        height: 25px;
        border-radius: 100%;
        margin-right: 5px;
        border: 2px solid $gray-second;
        // box-shadow: 0 0 10px 2px $gray;
      }
      &:hover {
        .color {
          border-color: $black;
        }
      }
      .active{
        .color {
          border-color: $black;
        }
      }
    }

    .facet-label {
      display: flex;
      align-items: center;
      margin: 10px 0;
      .custom-checkbox{
        top:auto!important;
      }
      a {
        display: inline-flex;
        justify-content: space-between;
        // margin-top: 0.4375rem;
        font-size: 14px;
        color: $gray;
      }
    }

    .collapse-icons{
      position: relative;
      width: 30px;
      height: 30px;
      display: block;
      cursor: pointer;
      &::before{
        content:'';
        position: absolute;
        display: block;
        width: 16px;
        height: 2px;
        background: $gray;
        top: 15px;
        right: 0;
        transition: .1s all linear;
        transform:rotate(180deg);
      }
      &::after{
        content:'';
        position: absolute;
        display: block;
        width: 16px;
        height: 2px;
        background: $gray;
        top: 15px;
        right: 0;
        transition: .2s all linear;
        transform:rotate(90deg);
      }
    }

    .title{
      padding: 0 0 10px;
      &.collapsed{
        .collapse-icons{
          &::before,
          &::after{
            transform:rotate(0deg)
          }
        }
      }
    }
  }
}

#search_filters_brands,
#search_filters_suppliers {
  .facet {
    padding-top: 0;

    .facet-label {
      margin-bottom: 0.3125rem;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
  padding: 15px;
  font-size: 14px;
  color: $gray-second;
  background: #fff;
  box-shadow: 3px 5px 10px #00000029;
  border-radius: 5px;
  // border-top: 1px solid $brand-primary;

  >div:first-child {
    line-height: 2.5rem;
  }

  .page-list {
    display: flex;
    padding: 0;
    margin-bottom: 0;
    text-align: right;
    justify-content: flex-end;

    li {
      display: inline;
      margin: 0 2px;
    }
  }

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 0 3px;
    color: $black;
    font-weight: 400;
    &:hover {
      color: $brand-primary;
    }
  }

  .previous,
  .next {
    color: $brand-primary;
    background: transparent;
    // border-radius: 0;
  }

  .disabled {
    color: $gray;
  }

  .current a {
    color: $brand-primary;
  }
}

.active_filters {
  background: $white;
  padding: 15px;
  margin-bottom: $medium-space;
  box-shadow: 3px 5px 10px #00000029;

  .active-filter-title {
    display: inline;
    margin-right: $small-space;
    font-weight: 600;
  }

  ul {
    display: inline;
  }

  .filter-block {
    color: $gray-darker;
    margin-right: $small-space;
    margin-bottom: $small-space;
    background: white;
    padding: 0.625rem;
    display: inline-block;
    font-size: $font-size-xs;

    .close {
      color: $gray-darker;
      font-size: $font-size-lg;
      opacity: 1;
      margin-top: 0.1875rem;
      margin-left: 0.3125rem;
    }
  }
}

.block-categories {
  padding: 25px;
  margin-bottom: 1.563rem;
  background: $white;
  box-shadow: 3px 5px 10px #00000029;
  border-radius: 5px;

  .category-sub-menu {
    margin: 0;

    .category-sub-link {
      font-size: $font-size-sm;
    }

    li {
      position: relative;

      &[data-depth="1"] {
        margin-bottom: $small-space;
      }
    }

    li[data-depth="0"]>a {
      display: inline-block;
      width: 100%;
      padding: 10px 0;
      padding-right: 2.5em;
      margin: 0;
      font-family: $secondary-font;
      font-weight: 700;
      color: $black;
      // border-bottom: 1px solid #eee;
    }

    li:not([data-depth="0"]):not([data-depth="1"]) {
      padding-left: 0.3125rem;
    }

    .category-sub-menu {
      padding-left: 15px;
    }
  }

  a {
    color: $black;

    &:hover {
      color: $brand-primary;
    }
  }

  .collapse-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    cursor: pointer;

    &[aria-expanded="true"] {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .add,
    .remove {
      &:hover {
        color: $brand-primary;
      }
    }

    .remove {
      display: none;
    }

    i {
      font-size: 20px;
      color: $gray;
    }
  }

  .arrows {
    position: absolute;
    right: 0;
    top: 0;

    .arrow-right,
    .arrow-down {
      font-size: 18px;
      cursor: pointer;
      margin-left: 2px;

      &:hover {
        color: $brand-primary;
      }
    }

    .arrow-down {
      display: none;
    }

    &[aria-expanded="true"] {
      .arrow-right {
        display: none;
      }

      .arrow-down {
        display: inline-block;
      }
    }
  }

  .current-category {
    >a {
      color: $brand-primary !important
    }
  }
}

.facets-title {
  color: $gray-darker;
}

.products-selection {
  .filter-button {
    .btn-secondary {
      padding: 0.75rem 0.5rem 0.6875rem;
    }
  }
}

.advertising-block {
  margin-bottom: 1.563rem;

  img {
    width: 100%;
  }
}

.left {
  &-title {
    display: block;
    padding: 0 0 20px;
    margin: 0;
    line-height: 1;
    font-weight: 900;
    font-size: 22px;
    color: $black;
    // border-bottom: 1px solid #4e4e4e;
  }
}

.category-bottom{
  padding: 25px 0;
  line-height: 1.5;
  color: $black;

  p {
    color: $black;
    font-size: 16px;
  }

  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 22px;
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  #category {
    #left-column {
      width: 100%;

      #search_filters_wrapper {
        margin-left: -30px;
        margin-right: -30px;
      }

      #search_filter_controls {
        text-align: center;

        button {
          margin: 0 0.5rem;
        }

        margin-bottom: 1rem;
      }

      #search_filters {
        margin-bottom: 20px;
        box-shadow: none;
        padding: 0;
        border-top: 1px solid $gray-lighter;

        .ui-slider-horizontal {
          margin-bottom: 20px;
        }

        .facet {
          .title {
            cursor: pointer;
            align-items: center;
            padding: 0;

            .collapse-icons .remove {
              display: none;
            }
          }

          .title[aria-expanded="true"] {
            .collapse-icons {
              .add {
                display: none;
              }

              .remove {
                display: block;
              }
            }
          }

          padding: 0 15px;
          border-bottom: 1px solid $gray-lighter;

          .facet-title {
            color: $black;
            text-transform: uppercase;
          }

          .h6 {
            margin-bottom: 0;
            padding: 15px 0;
            display: inline-block;
          }

          .navbar-toggler {
            display: inline-block;
            padding: 0 0.625rem 0 0;
            height: inherit;
          }

          .collapse {
            display: none;

            &.in {
              display: block;
            }
          }

          .facet-label {
            a {
              margin-top: 0;
            }
          }

          ul {
            margin-bottom: 0;

            li {
              border-top: 1px solid $gray-lighter;
              padding: 1.250rem;
            }
          }
        }
      }
    }

    #content-wrapper {
      width: 100%;
    }

    #search_filter_toggler {
      width: 100%;
    }
  }

  .products-sort-order {
    .select-title {
      margin-left: 0;
    }
  }

  .products-selection {
    margin: 0 0 1rem!important;

    h1 {
      padding-top: 0;
      text-align: center;
      margin-bottom: 1rem;
    }

    .showing {
      padding-top: 1rem;
    }
  }

  #prices-drop #content-wrapper,
  #new-products #content-wrapper,
  #best-sales #content-wrapper {
    width: 100%;
  }
}

@include media-breakpoint-down(xs) {
  .products-selection {
    .filter-button {
      padding-left: 15px;
      width: 100%;
    }
  }

  #category {
    #left-column {
      #search_filters_wrapper {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .category-cover {
    display: none;
  }

  .block-category {
  }

  .card-block {
    padding: 0;
  }

  .card {
    // box-shadow: none;
    // background-color: inherit;
    // border: none;
  }
}
