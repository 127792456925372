#wrapper {
  &.wrapper-subpage {
    width: 100%;
    padding: 2rem 0 0;
    background: $brand-secondary;
    // border-bottom: 2px solid $brand-secondary;
  }

  .banner {
    display: block;
    margin-bottom: 1.5rem;
  }

  .breadcrumb {
    // display: none;
    width: 100%;
    padding: 0px 15px;
    font-size: 13px;
    color: $gray;
    background: transparent;

    &[data-depth="1"] {
      display: none;
    }

    ol {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      display: inline;

      &::after {
        margin: 0.3125rem;
        color: $gray;
        content: "/";
      }

      &:last-child {
        margin: 0;
        color: $gray;
        content: "/";

        &::after {
          content: "";
        }
      }

      a {
        color: $gray;
      }
    }
  }
}
