#header {
  display: contents;
  position: relative;
  color: $gray;
  // border-bottom: 1px solid #4e4e4e;
  #menu-icon{
    padding: 15px 3px 3px;
  }

  .logo {
    max-width: 100%;
    width: 200px;
    padding: 10px 0;
    transition: .3s all linear;
  }


  .menu {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    // justify-content: end;

    &-container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }

    >ul {
      display: flex;
      margin: 0;
      padding: 0;
      text-align: center;

      >li {
        // position: relative;
        display: inline-block;

        &.home-main {
          svg {
            display: block;
            width: 20px;
            color: #fff;
          }
        }

        &.category-main {

          // background: $gray-darker;
          >a {
            display: flex;
            color: #fff;
            align-items: center;

            svg {
              width: 20px;
              height: auto;
              margin-right: 10px;
            }
          }

        }
      }
    }
  }

  .mobile {
    width: 100%;
    padding: 15px;

    .top-logo {
      width: 100%;
      margin: 0 0 20px;
      text-align: center;
    }

    .header-link {
      display: flex;
    }
  }

  // .header-register {
  //   border-bottom: 1px solid $gray-light;

  //   &-links {
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-end;
  //     padding: 5px 0;
  //   }

  //   &-link {
  //     display: inline-block;
  //     padding: 0 5px;
  //     color: $gray;

  //     &.login {
  //       padding-right: 20px;
  //       border-right: 1px solid $gray-light;
  //     }

  //     &.register {
  //       padding-left: 20px;
  //     }
  //   }
  // }

  .header-nav {
    // padding: 10px 0;
    background-color: $white;
    // border-bottom: 1px solid $gray-light;

    .mobile {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .mobile-icon {
      display: inline-flex;
      width: 40px;
      height: 40px;
      // line-height: 40px;
      justify-content: center;
      align-items: center;

      a {
        display: flex;
        color: $brand-primary;
      }

      i {
        color: $brand-primary;
      }

      svg {
        width: 20px;
        height: auto;
        color: $brand-primary;
      }
    }

    .right-nav {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    .currency-selector {
      margin-top: 0.9375rem;
      margin-left: 0.9375rem;
      white-space: nowrap;
    }

    .language-selector {
      margin-top: 0.9375rem;
      white-space: nowrap;
    }

    .cart-preview {
      .shopping-cart {
        vertical-align: middle;
        color: $gray;
      }

      .body {
        display: none;
      }
    }

    .material-icons {
      line-height: inherit;

      &.expand-more {
        margin-left: -0.375rem;
      }
    }
  }
  .header-top {
    background-color: $white;
    @media(min-width: 992px){
      padding: 10px 0;
      background-color: $brand-secondary;
      border-top: 2px solid $brand-primary;
    }

    >.container {
      position: relative;
    }

    .menu {
      padding-left: 15px;
      margin-bottom: 0.375rem;
    }

    .position-static {
      position: static;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    // a[data-depth="0"] {
    //   color: $gray;
    //   text-transform: uppercase;
    // }
  }

  .top-menu-link {
    margin-left: $medium-space;
  }

  .header-menu {
    position: relative;
    // background: $brand-secondary;
    // border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;

    .container {
      display: flex;
      align-content: center;
      justify-content: flex-start;
    }
  }
}

.header-banner {
  .banner-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.header-language {
  display: inline-block;
  margin-left: 20px;
}

.header-btn {
  position: relative;
  display: none;
  padding: 5px;
  // margin-left: 15px;

  a {
    cursor: pointer;
  }

  .header-link {
    display: inline-block;
    text-align: center;
    color: $brand-primary;
    font-size: 14px;
  }


  &:hover {
    .header-icon {
      // background: $brand-primary;
      // box-shadow: 0 0 15px #00000029;

      svg {
        color: $brand-primary;
      }
    }
  }

  .cart-products-count {
    position: absolute;
    right: -5px;
    top: -10px;
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    background: $brand-primary;
    border-radius: 100%;
  }
}

.header-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // width: 30px;
  // height: 30px;
  padding: 0 5px;
  svg{
    display: block;
    width: 23px;
    height: 23px;
    color: $brand-primary;
  }
}

.header-name {
  display: none;
}


.nav-center {
  padding: 20px 0;
  text-align: center;
}

.nav-left,
.nav-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .nav-left {
    justify-content: start;
  }

  .nav-right {
    justify-content: flex-end;
  }
}

@media(min-width: 992px) {
  #header {
    .header-sticky {
      // position: sticky;
      // top: -1px;
      // z-index: 99;
      // background: $white;
    }
  }

  .header-btn {
    display: inline-block;
    .header-name {
      display: block;
    }
  }
}